@use 'src/utils/theme/variables' as *;

.PasswordCheck {
	.invalid {
		color: map-get($colors, "red");
	}

	.valid {
		color: map-get($colors, "teal");
	}
}
