@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');


@mixin roboto($important: true) {
	@if ($important == true) {
		font-family: "Roboto", sans-serif !important;
		font-style: normal !important;
	}
	@else {
		font-family: "Roboto", sans-serif;
		font-style: normal;
	}
}
@mixin roboto-slab($important: true) {
	@if ($important == true) {
		font-family: "Roboto Slab", sans-serif !important;
		font-style: normal !important;
	}
	@else {
		font-family: "Roboto Slab", sans-serif;
		font-style: normal;
	}
}
@mixin inter($important: true) {
	@if ($important == true) {
		font-family: "Inter", sans-serif !important;
		font-style: normal !important;
	}
	@else {
		font-family: "Inter", sans-serif;
		font-style: normal;
	}
}
