@use 'src/utils/theme/variables' as *;

.CustomTabView {
	border-bottom: 1px solid $gray-300;
	&-item {
		&:not(&-action) {
			border-bottom: 2px solid transparent;
			margin-bottom: -1px;
			padding: 10px 10px;
			color: $gray-500;
		}
		&:not(&-action):hover {
			border-bottom: 2px solid $gray-400;
		}
		&-selected:not(&-action) {
			color: $primary-100;
			border-bottom: 2px solid $primary-100;
			&:hover {
				border-bottom: 2px solid $primaryDarkColor;
			}
		}
	}

	.p-badge {
		height: 1.2rem;
		line-height: 1.2rem;
		min-width: 1.2rem;
	}
}
