.readonly {
	.sun-editor {
		background: transparent;
		border: none;
		.se-wrapper-inner {
			padding: 0;
			background: transparent;
		}

		.se-resizing-bar {
			display: none;
		}
	}
}
